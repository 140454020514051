import { userService } from '../services';
import {
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_CATALOG,
  LOAD_MORE_SEARCH_RESULTS_CATALOG,
  CHANGE_PRODUCT_SEARCH,
  GET_SEARCH_RESULTS_CATALOG_LOADING,
  SEARCH_BRANDS
} from '../config/actionTypes';
import {categoriesFunc} from "./categories.actions";

export const search = {
    getSearch,
    getSearchShop,
    loadMoreSearchShop,
    changeProductSearch,
    searchBrands
};

function getSearch(params) {
    return dispatch => {
        userService.PostInfo('/search', params)
            .then(
                result => {
                    dispatch(success(result));
                }
            );
    };
    function success(result) { return { type: GET_SEARCH_RESULTS, result } }
}
function getSearchShop(params) {
  return dispatch => {
        dispatch(request());
        userService.PostInfo('/search', params)
            .then(
                (result) => {
                    const {searchCriteria} = result;
                    dispatch(categoriesFunc.categories({...searchCriteria}))
                    dispatch(categoriesFunc.brands({...searchCriteria}))
                    dispatch(categoriesFunc.personalities({...searchCriteria}))
                    dispatch(categoriesFunc.productTypes({...searchCriteria}))
                    dispatch(categoriesFunc.featuredBrands({...searchCriteria}))
                    dispatch(categoriesFunc.discover({...searchCriteria}))
                    return dispatch(success(result));
                }
            );
    };
    function request() { return { type: GET_SEARCH_RESULTS_CATALOG_LOADING, result: null, loading: true } }
    function success(result) { return { type: GET_SEARCH_RESULTS_CATALOG, result, loading: false } }
}
function loadMoreSearchShop(params) {
    return dispatch => {
        dispatch(requestLoader());
        return userService.PostInfo('/search', params)
            .then(
                result => {
                    dispatch(success(result));
                    dispatch(successLoader());
                },
                () =>{
                    dispatch(failureLoader());
                }
            );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
    function success(result) { return { type: LOAD_MORE_SEARCH_RESULTS_CATALOG, result } }
}
function changeProductSearch(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(changeProduct) { return { type: CHANGE_PRODUCT_SEARCH, changeProduct } }
}
function searchBrands(data) {
    return dispatch => {
        dispatch(success(data));
    };
    function success(searchValue) { return { type: SEARCH_BRANDS, searchValue } }
}