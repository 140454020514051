import React, {useRef, useContext, useState} from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import InputZip from "../forms/InputZip";
import StyledPlacesContainer from "./LocationSearchInput.styled";
import StyledLocationWarningCopy from "./LocationWarningCopy.styled";
import {ThemePramsContext} from "../../App";
import {useHistory} from "react-router-dom";
import {selectedTheme} from "../../config/constants";
import rebudTheme from "../../styles/themes/DefaultTheme";
import SVGIcon from "../../components/utility/SVGIcon";
import * as toast from "../utility/toast";
import ButtonOutlineCurrentLocation from "../ui/ButtonOutlineCurrentLocation";

const LocationSearchInput = React.memo(props => {
    const {
        accountPage,
        address,
        handleChange,
        handleSelect,
        notDeliveryModal,
        suggestionData,
        disabled,
        notDelivery,
        required,
        placeholder,
        streetSearch,
        locationSearch,
        className,
        errorMessage,
        setIsAddress,
        onBlur,
        handleWrongAddress,
        openPopup,
        isShipping
    } = props;
    const myRef = useRef();

    const onSelectAddress = address => {
        geocodeByAddress(address)
            .then(results => {
                setIsAddress && setIsAddress(true);
                // handleSelect(results[0].formatted_address)
                handleSelect(address)
            })
            .catch(() => {
                setIsAddress && setIsAddress(false)
                handleWrongAddress && handleWrongAddress()
            } );
    };
    const getCurrentLocation = (e) => {
        e.stopPropagation()
        e.preventDefault()
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    // Optionally, you can reverse geocode the coordinates to get an address
                    // and then update the input field with the current location's address.
                    geocodeByAddress(`${currentLocation.lat}, ${currentLocation.lng}`)
                        .then((results) => {
                            const currentAddress = results[0].formatted_address;
                            handleChange(currentAddress);
                            handleSelect(currentAddress);
                        })
                        .catch((error) => {
                            toast.error({ text: error.message })
                        });
                },
                (error) => {
                    toast.error({ text: error.message })

                }
            );
        } else {
            toast.error({ text: "Geolocation is not supported by this browser" })
        }
    };
    const {checkoutConfig:{themeLocationSearchInput}} = useContext(ThemePramsContext);
    const path = useHistory().location.pathname;
    return (
        <PlacesAutocomplete

            searchOptions={!isShipping ? {
                types: streetSearch ? ['address'] : [],
                componentRestrictions: { country: "us"} ,
                location: new window.google.maps.LatLng(36.778259,-119.417931),
                radius: 762000
            } : {
                types: streetSearch ? ['address'] : [],
                componentRestrictions: { country: "us"} ,
            }}
            value={address}
            onChange={address=> {
                const re =/^[\w-_.#&',/ áéíóúüñ\u2014\u2013]*$/;
                if (address === '' || re.test(address)) {
                    handleChange(address)
                }
                setIsAddress && setIsAddress(false);
            }}
            onSelect={onSelectAddress}
            ref={myRef}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <>
                    { path === '/checkout' && selectedTheme === "FlowerTheme" ?
                        <InputZip
                            {...getInputProps({
                                placeholder: `${placeholder ? placeholder : "Enter Your Location" }`,
                                name: "address",
                                className: `location-search-input ${themeLocationSearchInput.font1} ${disabled ? "disabled" : ""} ${className}`,
                                required: required,
                                onBlur  : onBlur,
                                disabled: openPopup || disabled,
                                font1: 't-form-input',
                                font2: '',
                                font3: '',
                                zipColor1: rebudTheme.colors.white,
                                zipColor2: rebudTheme.colors.gray3,
                                padding: '1.375rem 16px',
                                height1: '64px',
                                height2: '64px',
                                borderRadius: '4px',
                                border: `solid 1px ${rebudTheme.colors.gray2}`,
                                borderBottom: ``,
                                font4: ''
                            })}
                        /> :
                        <InputZip
                            {...getInputProps({
                                placeholder: `${placeholder ? placeholder : "Enter Your Location" }`,
                                name: "address",
                                className: `location-search-input ${themeLocationSearchInput.font1} ${disabled ? "disabled" : ""} ${className}`,
                                required: required,
                                onBlur  : onBlur,
                                disabled: openPopup || disabled,
                                ...themeLocationSearchInput
                            })}
                        />}
                    {!accountPage &&
                        <ButtonOutlineCurrentLocation  onClick={getCurrentLocation}>
                            <SVGIcon name="location" width={16} height={16} />Use My Location
                        </ButtonOutlineCurrentLocation>
                    }

                    <StyledPlacesContainer
                        className={`autocomplete-dropdown-container ${themeLocationSearchInput.font2} ${
                            suggestions.length > 1 ? "active" : ""
                        }`}
                        color1={themeLocationSearchInput.color1}
                        color2={themeLocationSearchInput.color2}
                    >
                        {loading && <div>Loading...</div>}

                        {(suggestionData? suggestionData : suggestions).map(suggestion => {
                            const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                            return (
                                <>

                                    <div
                                        key={suggestion.id}
                                        {...getSuggestionItemProps(suggestion, {
                                            className
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                </>

                            );
                        })}
                    </StyledPlacesContainer>
                    {notDelivery && !isShipping && <StyledLocationWarningCopy className={`${themeLocationSearchInput.font3} t-body-small`}>Delivery isn’t available in your location, but you can view the shop.</StyledLocationWarningCopy>}
                    {notDelivery && isShipping && <StyledLocationWarningCopy className={`${themeLocationSearchInput.font3} t-body-small`}>Shipping isn’t available in your location, but you can view the shop.</StyledLocationWarningCopy>}
                    {errorMessage && <StyledLocationWarningCopy className={`${themeLocationSearchInput.font3} t-body-small`}>Delivery isn’t available in this location</StyledLocationWarningCopy>}
                    {locationSearch && <StyledLocationWarningCopy className={`${themeLocationSearchInput.font3} t-body-small`}>Please select a full shipping address.</StyledLocationWarningCopy>}
                    {notDeliveryModal && <span className={`${themeLocationSearchInput.font3} t-body-small`}>Unserviceable</span>}

                </>
            )}
        </PlacesAutocomplete>
    );
});

LocationSearchInput.propTypes = {
    address: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired,
    notDelivery: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string
};
LocationSearchInput.defaultProps = {
    disabled: false
};
export default LocationSearchInput;
